<template>
  <v-card color="grey lighten-4" flat height="50" fill-width tile>
    <v-app-bar @click="drawer = true" fill-width>
      <!--<v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>-->
      <v-menu
        :rounded="rounded"
        open-on-hover
        offset-y
        transition="slide-x-transition"
        bottom
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
        </template>

        <v-list dense v-if="isUserLoggedOn">
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            Logout
          </v-list-item>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            router
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-action style="cursor:pointer">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-toolbar-title>Pharma Literature Search </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-side-icon>
        <div v-if="getLogo === 0" class="pb-5">
          <v-img src="@/assets/hkb-abf.png" height="50px" width="320px">
          </v-img>
        </div>
        <div v-if="getLogo === 1">
          <v-img src="@/assets/adcock-logo.png" height="50px" width="320px">
          </v-img>
        </div>
        <div v-if="getLogo === 2">
          <v-img src="@/assets/demo-coy.png" height="50px" width="320px">
          </v-img>
        </div>
      </v-toolbar-side-icon>
    </v-app-bar>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    drawer: false,
    items: [
      { title: "Latest Report", icon: "mdi-view-list", link: "litrpt" },
      { title: "Latest Pubmed Report", icon: "mdi-view-list", link: "pubrpt" },
      { title: "View History", icon: "mdi-view-dashboard", link: "history" },
      {
        title: "View Pubmed History",
        icon: "mdi-view-dashboard",
        link: "pubhistory",
      },
      {
        title: "Version 1.0",
        icon: "mdi-information-outline",
        link: "",
      },
      { title: "About", icon: "mdi-help-box", link: "" },
    ],
  }),
  methods: {
    async logout() {
      sessionStorage.removeItem("user");
      await this.$store.dispatch("setUpUser", null);
      this.$router.push("/login");
    },
  },
  computed: {
    getLogo() {
      if (this.$store.getters.getUser) {
        return this.$store.getters.getUser.CustomerId;
      } else {
        return 0;
      }
    },
    isUserLoggedOn() {
      return this.$store.getters.getUser;
    },
  },
};
</script>
<style>
.v-navigation-drawer__content::-webkit-scrollbar-track {
  background-color: #5d5d5d;
}
.v-navigation-drawer__content::-webkit-scrollbar {
  width: 0px;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background-color: #424242;
}
.v-navigation-drawer__content {
  cursor: pointer;
}
</style>
