<template>
  <v-container fluid>
    <div @contextmenu="show">
      <div class="pt-2">
        <v-card height="30px" width="800px" flat></v-card>
      </div>
      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list>
          <v-list-item-group v-model="item" color="primary">
            <v-list-item
              v-for="(item, index) in m_items"
              :key="index"
              @click="fetch(index)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.title"
                  name="lists"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
    <v-card class="test_cursor" flat>
      <pSelect :items="items" @update-product="updateProduct" />
      <sSelect :items="allJournals" />
    </v-card>
    <v-row class="d-flex justify-center">
      <fPicker :lbl="startLbl" @update-from="updateFrom" />
      <tPicker :lbl="endLbl" @update-to="updateTo" />
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="6" lg="4">
        <v-btn color="light-blue" class="white--text" @click="submit">
          Run Search
          <v-icon right>
            mdi-magnify
          </v-icon>
        </v-btn>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import sSelect from "@/components/JournalSelect";
import pSelect from "@/components/ProductSelect";
import fPicker from "@/components/DateFromPicker";
import tPicker from "@/components/DateToPicker";
//import sbtn from "@/components/SubmitBtn";
export default {
  components: {
    sSelect,
    pSelect,
    fPicker,
    tPicker,
  },
  data: () => ({
    items: [],
    m_items: [
      {
        val: 0,
        title: " Latest Report ",
        icon: "mdi-view-list",
      },
      {
        val: 1,
        title: " View History ",
        icon: "mdi-view-dashboard",
      },
    ],
    value: [],
    pAll: false,
    jAll: false,
    siteItems: [],
    allJournals: [],
    allSites: [],
    startDate: null,
    startLbl: "Start Date DD/MM/YYYY",
    endLbl: "End Date DD/MM/YYYY",
    val: 0,
    visible: false,
    overlay: false,
    zIndex: 10,
    showMenu: false,
    x: 0,
    y: 0,
    item: 0,
    searchObj: {
      startDate: new Date(),
      endDate: new Date(),
      userId: 0,
      searchProducts: {
        all: false,
        products: [],
      },
      searchSites: {
        all: false,
        sites: [],
      },
    },
  }),
  methods: {
    async submit() {
      if (this.searchObj.searchProducts.products.length < 1) {
        alert("No products Selected- Select at least 1 product");
        return;
      }
      if (this.searchObj.searchProducts.products.length > 4) {
        alert("Not more than 4 products may be Selected");
        return;
      }
      if (
        new Date(this.searchObj.endDate) <= new Date(this.searchObj.startDate)
      ) {
        alert("End Date of search must be greater than Start Date!");
        return;
      }

      this.overlay = true;
      this.searchObj.userId = this.$store.getters.getUser.id;
      await this.$store.dispatch("runSearch", this.searchObj);
      console.log("obj " + JSON.stringify(this.searchObj));
      this.overlay = false;

      this.$router.push({
        path: "/report",
      });
    },
    getSiteIds(list) {
      let idList = [];
      list.forEach((item) => {
        idList.push(item.id);
      });
      return idList;
    },
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;

      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    fetch(index) {
      if (index === 0) {
        this.$router.push({
          name: "litrpt",
        });
      }
      if (index === 1) {
        this.$router.push({
          name: "history",
        });
      }
    },
    updateProduct(e) {
      this.searchObj.searchProducts.products = e;
    },
    updateFrom(e) {
      this.searchObj.startDate = e;
    },
    updateTo(e) {
      this.searchObj.endDate = e;
    },
  },
  async created() {
    //This was created a week before the first search against new products
    this.$store.dispatch("findJuly24Products").then((products) => {
      this.items = products;
    });
    this.$store.dispatch("findJournals").then((journals) => {
      this.allJournals = journals;
    });
    this.$store.dispatch("findSites").then((sites) => {
      this.searchObj.searchSites.sites = sites;
    });
  },
};
</script>
<style scoped>
.test_cursor input {
  cursor: pointer;
}
</style>
