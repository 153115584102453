<template>
  <v-row class="d-flex justify-center">
    <v-col cols="8">
      <v-autocomplete
        :items="items"
        item-text="name"
        item-value="id"
        outlined
        dense
        label="Journals List"
        class="test_cursor"
      >
        <v-list-tile slot="prepend-item" class="grey--text">
          {{ items.length }} Journals Listed
        </v-list-tile>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      value: [],
    },
  },
};
</script>
<style scoped>
.test_cursor input {
  cursor: pointer;
}
</style>
