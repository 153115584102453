<template>
  <v-col cols="12" lg="5">
    <v-menu
      ref="startMenu"
      v-model="startMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      min-width="290px"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFormatted"
          class="mt-3"
          :label="lbl"
          prepend-icon="mdi-calendar"
          dense
          outlined
          readonly
          hide-details
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        year-icon="mdi-calendar-blank"
        prev-icon="mdi-skip-previous"
        next-icon="mdi-skip-next"
        scrollable
        :max="new Date().toISOString().substr(0, 10)"
        min="2010-01-01"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="startMenu = false">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="$refs.startMenu.save(date)">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </v-col>
</template>

<script>
export default {
  props: {
    dateVar: {
      type: Date,
      value: null,
    },
    lbl: {
      type: String,
      value: null,
    },
  },
  data: (vm) => ({
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    startMenu: false,
    nowMenu: false,
  }),
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.$emit("update-to", this.date);
    },
  },
};
</script>
