//import Vue from "vue";
//import VueRouter from "vue-router";
import Rpt from "@/components/ProductSearchRpt.vue";
import LitRpt from "@/components/LitSearchRpt.vue";
import Login from "@/components/Login.vue";
import RunSearch from "@/components/RunSearch1";
import History from "@/components/History.vue";
import HistSearchRpt from "@/components/HistorySearchRpt.vue";
import JournalProdHist from "@/components/JournalProdHist.vue";
import ProductsJournalsRpt from "../components/ProductsJournalsRpt.vue";
import ActiveJournalsRpt from "../components/ActiveJournalsRpt.vue";
import PubLitRpt from "../components/PubMedSearchRpt.vue";
import PubHistLitRpt from "../components/PubMedHistSearchRpt.vue";
import PubMedHistory from "../components/PubMedHistory.vue";
import ProdSearchReport from "../components/ProductSearchRpt";
const { store } = require("@/store");

//Vue.use(VueRouter);

import jwt_decode from "jwt-decode";
/*
const checkAuthentication = (to, from, next) => {
  if (store.state.loggedIn) {
    next();
    return;
  }
  next("/login");
};*/
function assertAlive(decoded) {
  const now = Date.now().valueOf() / 1000;
  if (typeof decoded.exp !== "undefined" && decoded.exp < now) {
    throw new Error(`token expired: ${JSON.stringify(decoded)}`);
  }
  if (typeof decoded.nbf !== "undefined" && decoded.nbf > now) {
    throw new Error(`token not yet valid: ${JSON.stringify(decoded)}`);
  }
}
const checkIfAuthenticated = (to, from, next) => {
  var user = JSON.parse(sessionStorage.getItem("user"));
  var token = sessionStorage.getItem("token");
  if (user) {
    try {
      assertAlive(jwt_decode(token));
    } catch (error) {
      next("/login");
    }
    store
      .dispatch("setUpUser", user)
      .then(() => {
        store
          .dispatch("setUpToken", JSON.parse(sessionStorage.getItem("token")))
          .then(() => {
            var token = store.getters.getToken;
            if (token) {
              next("/search");
              return;
            } else {
              next("/login");
            }
          });
      })
      .catch((err) => {
        throw err;
      });
  } else {
    if (process.env.VUE_APP_ADMIN_REQ === 1) {
      next("/signup");
    } else {
      next("/login");
    }
  }
};

export default [
  {
    path: "/",
    name: "home",
    component: Login,
    beforeEnter: checkIfAuthenticated,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Login,
    beforeEnter: checkIfAuthenticated,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/report",
    name: "report",
    component: Rpt,
    //beforeEnter: checkAuthentication,
  },
  {
    path: "/litrpt",
    name: "litrpt",
    component: LitRpt,
    //beforeEnter: checkAuthentication,
  },
  {
    path: "/histRpt/:sObj",
    name: "histRpt",
    component: HistSearchRpt,
    //beforeEnter: checkAuthentication,
  },
  {
    path: "/histProduct",
    name: "histProd",
    component: JournalProdHist,
    //beforeEnter: checkAuthentication,
  },
  {
    path: "/actjnls",
    name: "activeJnls",
    component: ActiveJournalsRpt,
    //beforeEnter: checkAuthentication,
  },
  {
    path: "/search",
    name: "search",
    component: RunSearch,
    //beforeEnter: checkAuthentication,
  },
  { path: "/login", name: "arbitLogin", component: Login },
  { path: "/history", name: "history", component: History },
  { path: "/pubhistory", name: "pubhistory", component: PubMedHistory },
  {
    path: "/prodjournalrpt/:sObj",
    name: "prodjournalrpt",
    component: ProductsJournalsRpt,
  },
  {
    path: "/pubrpt",
    name: "pubrpt",
    component: PubLitRpt,
    //beforeEnter: checkAuthentication,
  },
  {
    path: "/pubhistrpt/:sObj",
    name: "pubhistrpt",
    component: PubHistLitRpt,
    //beforeEnter: checkAuthentication,
  },
  {
    path: "/prodsearchrpt/:sObj",
    name: "prodsearchrpt",
    component: ProdSearchReport,
    //beforeEnter: checkAuthentication,
  },
];
