<template>
  <v-container fluid>
    <Context
      :showMenu="showMenu"
      :viewProds="viewProds"
      ref="menu"
      @fetch-type="fetchType"
    ></Context>
    <h3 class="pt-1 text-center">Search History</h3>
    <br />
    <v-row class="d-flex justify-center" style="margin-bottom:30px">
      <v-data-table
        v-model="selected"
        dense
        :headers="headers"
        :items="items"
        item-key="id"
        class="elevation-1"
        @contextmenu:row="rightClickHandler"
      >
      </v-data-table>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-btn
        color="light-blue"
        class="white--text d-flex"
        small
        @click="returnHome"
        >home
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import Context from "@/components/ContextMenu";
export default {
  components: {
    Context,
  },
  data() {
    return {
      headers: [
        {
          text: "Start Date",
          align: "center",
          sortable: false,
          value: "f_date",
        },
        { text: "End Date", value: "s_date" },
        { text: "Created", value: "created" },
      ],
      items: [],
      item: {},
      showMenu: false,
      viewProds: true,
      e_vent: "",
      color: "grey",
      counter: 0,
      rec: {
        id: 0,
        s_date: "",
        f_date: "",
        s_response: {},
        pubmed: 0,
        created: "",
      },
      selected: [],
    };
  },
  methods: {
    rightClickHandler(e, item) {
      this.showMenu = true;
      e.preventDefault();
      item.pubmed = 0;
      this.item = item;
      this.$refs.menu.show(e, item);
    },
    fetchType(e) {
      switch (e) {
        case "Download Report":
          this.$router.push({
            name: "histRpt",
            params: {
              sObj: this.item,
            },
          });
          break;
        case "View Products/Journals":
          this.$router.push({
            name: "histProd",
          });
          break;
        case "Pdf Products/Journals":
          this.$router.push({
            name: "prodjournalrpt",
            params: {
              sObj: this.item,
            },
          });
          break;
      }
    },
    returnHome() {
      this.$router.push({
        path: "/",
      });
    },
    toLocalDate(dte, toIso) {
      let date = new Date(dte);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      if (toIso) return `${year}-${month}-${dt}`;
      else return `${dt}-${month}-${year}`;
    },
  },
  async created() {
    let foo = await this.$store.dispatch("findLitSearches");
    foo.forEach((line) => {
      this.rec = {};
      this.rec.id = line.id;
      this.rec.f_date = this.toLocalDate(line.f_date, false);
      this.rec.s_date = this.toLocalDate(line.s_date, false);
      this.rec.created = line.created;
      this.rec.s_response = line.s_response;
      this.rec.pubmed = 0;
      this.items.push(this.rec);
    });
  },
};
</script>
<style scoped>
p.ex1 {
  margin-top: 125px;
}
</style>
