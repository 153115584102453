var df = require("dateformat");
exports.getFormattedDate = (d, long, longer) => {
  var day = d.getDate();
  var days = day < 10 ? "0" + day : day;
  var month = d.getMonth() + 1;
  var mnth = month < 10 ? "0" + month : month;
  var year = d.getFullYear();
  if (long) {
    return `${days}/${mnth}/${year}`;
  }
  if (longer) {
    return df(d, "dd mmmm yyyy");
  }
  return `${days}/${mnth}`;
};
