<template>
  <v-container fluid>
    <v-card class="test_cursor" flat>
      <pSelect :items="items" @update-product="updateProduct" :hist="hist" />
      <sSelect :items="allJournals" />
    </v-card>
    <v-row class="d-flex justify-center">
      <v-col cols="6" lg="4">
        <v-btn color="light-blue" class="white--text" @click="returnHome">
          Back
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import sSelect from "@/components/JournalSelect";
import pSelect from "@/components/ProductSelect";
export default {
  components: {
    sSelect,
    pSelect,
  },
  data: () => ({
    items: [],
    allJournals: [],
    hist: true,
  }),
  methods: {
    updateProduct(e) {
      console.log(e);
    },
    returnHome() {
      this.$router.push({
        name: "history",
      });
    },
  },
  async created() {
    this.$store.dispatch("findProducts").then((products) => {
      this.items = products;
    });
    this.$store.dispatch("findJournals").then((journals) => {
      this.allJournals = journals;
    });
  },
};
</script>
