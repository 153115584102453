<template>
  <v-layout>
    <v-flex>
      <br />
      <v-btn color="light-blue" class="white--text" small @click="returnHome"
        >Back
      </v-btn>
    </v-flex>
  </v-layout>
</template>
<script>
import jspdf from "jspdf";
import { getFormattedDate } from "@/services/dateFormatter.js";

export default {
  data() {
    return {
      resolution: {},
      moneyConfig: {
        decimal: ".",
        thousands: ",",
        prefix: "R ",
        precision: 2,
        masked: false,
      },
      doc: {},
      caseId: 0,
      startDate: null,
      endDate: null,
      createdAt: null,
      productsFound: [],
      journals: 0,
    };
  },
  methods: {
    async initiateReport(allProducts, allJournals) {
      this.doc = new jspdf({ filters: ["ASCIIHexEncode"] });
      this.doc.setDrawColor(100, 100, 0);
      this.doc.page = 1;
      this.doc.setFont("Helvetica"); // set font
      this.doc.setFontSize(11);
      this.header();

      let ypos = 50;
      let rectWidth = this.doc.internal.pageSize.width - 11;

      let count = 1;
      for (let i = 0; i < allProducts.length; i++) {
        let prodApi = allProducts[i].name;
        let prods = prodApi.split("::");
        let name = prods[0];
        let api = prods[1];
        api = await this.cleanUpText(api);
        let rectHeight = this.calcRectHeight(4, api);

        if (ypos + rectHeight > 260) {
          this.addPageFiller(ypos, count, rectWidth);
          await this.footer();
          await this.doc.addPage();
          this.doc.page++;
          ypos = await this.header();
          ypos += 4;
        }

        if (this.isEven(count)) this.doc.setFillColor(230, 230, 250);
        if (this.isOdd(count)) this.doc.setFillColor(220, 210, 240);

        this.doc.rect(5.5, ypos - 5, rectWidth, rectHeight, "F");
        count++;
        this.doc.setTextColor(180, 100, 20);
        this.doc.text(13, ypos, "Product Name:");
        this.doc.setTextColor(0, 0, 0);
        this.doc.text(40, ypos, name);
        ypos += 6;
        this.doc.setTextColor(180, 100, 20);
        this.doc.text(30, ypos, "API:");
        this.doc.setTextColor(0, 0, 0);
        this.doc.text(39, ypos, api);
        ypos += this.noLinesInTxt(api) < 4 ? 6 : this.noLinesInTxt(api) * 3;

        this.doc.setDrawColor(0, 0, 255);
      }
      this.addPageFiller(ypos, count, rectWidth, true);
      this.footer();
      if (!allJournals) {
        await this.doc.save(
          "Searched Products and journals Pubmed Adcock Ingram.pdf"
        );
        return;
      }
      //this.header();
      this.addPageFiller(ypos, count, rectWidth);
      await this.footer();
      await this.doc.addPage();
      this.doc.page++;
      ypos = await this.header();
      ypos += 4;

      for (let i = 0; i < allJournals.length; i++) {
        let journalName = allJournals[i].name;
        journalName = await this.cleanUpText(journalName);
        let rectHeight = 7;
        if (ypos + rectHeight > 260) {
          this.addPageFiller(ypos, count, rectWidth);
          await this.footer();
          await this.doc.addPage();
          this.doc.page++;
          ypos = await this.header();
          ypos += 4;
        }

        if (this.isEven(count)) this.doc.setFillColor(230, 230, 250);
        if (this.isOdd(count)) this.doc.setFillColor(220, 210, 240);

        this.doc.rect(5.5, ypos - 5, rectWidth, rectHeight, "F");
        count++;
        this.doc.setTextColor(180, 100, 20);
        this.doc.text(25, ypos, "Journal:");
        this.doc.setTextColor(0, 0, 0);
        this.doc.text(40, ypos, journalName);
        ypos += 6;
        this.doc.setDrawColor(0, 0, 255);
      }
      this.addPageFiller(ypos, count, rectWidth, true);
      this.footer();
      if (this.journals == 0) {
        await this.doc.save(
          "Searched Products and Pubmed Journals Adcock Ingram.pdf"
        );
      } else {
        await this.doc.save("Searched Products and Journals Adcock Ingram.pdf");
      }
    },
    async setCounters(cObj, txtLines) {
      cObj.posx = 12;
      cObj.posy = cObj.posy > 0 ? cObj.posy + cObj.ch + 10 : 40;
      cObj.ch = txtLines.length * 5 + 20;

      return cObj;
    },
    calcRectHeight(initHeight, txt) {
      let h = initHeight;
      let prodName = 6;
      h += prodName;
      let txtHeight = this.noLinesInTxt(txt) * 4;
      h += txtHeight;
      return h;
    },
    encode_utf8(s) {
      return unescape(encodeURIComponent(s));
    },
    cleanUpText(txt) {
      txt = txt.replace(/[^a-zA-Z0-9' '/,/.:\n/*+-]/g, "");
      txt = this.encode_utf8(txt);
      if (this.isUpper(txt)) {
        txt = txt.replace(/(.{1,70})(?:\n|$| )/g, "$1\n");
      } else {
        txt = txt.replace(/(.{1,90})(?:\n|$| )/g, "$1\n");
      }
      return txt;
    },
    isEven(x) {
      return x % 2 == 0;
    },
    isOdd(x) {
      return !this.isEven(x);
    },
    isUpper(str) {
      return !/[a-z]/.test(str) && /[A-Z]/.test(str);
    },
    formatDateRange(date) {
      const [day, month, year] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    noLinesInTxt(txt) {
      return txt.split(/\n/).length + 1;
    },
    addPageFiller(ypos, count, w, done) {
      let h = this.doc.internal.pageSize.height - 15.5;
      h -= ypos;
      if (this.isEven(count)) this.doc.setFillColor(230, 230, 250);
      if (this.isOdd(count)) this.doc.setFillColor(220, 210, 240);
      this.doc.rect(5.5, ypos - 5, w, h, "F");
      this.doc.setFontSize(12);
      if (done) {
        this.doc.text(
          40,
          ypos + 10,
          "----------------------End Of Report--------------------"
        );
      }
    },
    async getLongestLine(txtLines) {
      var l = 0;
      for (var i = 0; i < txtLines.length; i++) {
        l = txtLines[i].length > l ? txtLines[i].length : 180;
      }
      if (l < 181) {
        l = 180;
      } else {
        l += 9;
      }
      return l;
    },
    async header() {
      this.doc.setFillColor(255, 255, 250);
      this.doc.roundedRect(
        5,
        5,
        this.doc.internal.pageSize.width - 10,
        this.doc.internal.pageSize.height - 10,
        5,
        5,
        "FD"
      );
      this.doc.setFontSize(12);
      this.doc.setTextColor(0, 0, 0);
      this.doc.line(5, 43, 205, 43, "FD");
      this.doc.setFontSize(18);
      this.doc.setTextColor(100, 100, 100);
      if (this.journals == 1) {
        this.doc.text(
          40,
          15,
          "Products and Journals used in Literature Search"
        );
      } else {
        this.doc.text(40, 15, "Products and Journals Pubmed Literature Search");
      }

      this.doc.setFontSize(18);
      this.doc.text(80, 24, "Adcock Ingram");
      this.doc.setFontSize(10);
      this.doc.setTextColor(100, 100, 100);

      this.doc.text(163, 24, "Date Range:");
      this.doc.text(185, 24, this.formatDateRange(this.startDate));
      this.doc.text(192, 28, "To");
      this.doc.text(185, 32, this.formatDateRange(this.endDate));

      this.doc.setFontSize(12);
      this.doc.text(80, 32, getFormattedDate(new Date(), false, true));
      this.doc.setDrawColor(0, 0, 0);
      this.doc.setFontSize(10);
      this.doc.setTextColor(0, 0, 0);
      let ypos = 36;
      return ypos;
    },
    async footer() {
      this.doc.setFontSize(9);
      var ph = this.doc.internal.pageSize.height;
      this.doc.setLineWidth(0.1);
      this.doc.setDrawColor(0, 0, 0);
      this.doc.line(5, ph - 20, 205, ph - 20, "FD");
      /*
      const logo = require("@/assets/hkb-abf.png");
      var image = new Image();
      image.src = logo;
      this.doc.addImage(image, "PNG", 10, ph - 14, 30, 20);*/
      this.doc.line(5, ph - 20, 205, ph - 20, "FD");
      this.doc.text(10, ph - 14, "HKB-tech");
      this.doc.text(
        160,
        ph - 14,
        "Date created: " + getFormattedDate(new Date(), true)
      );

      this.doc.text(185, ph - 8, " page: " + this.doc.page);
    },
    returnHome() {
      if (this.journals == 1) {
        this.$router.push({
          name: "history",
        });
      } else {
        this.$router.push({
          name: "pubhistory",
        });
      }
    },
  },
  async created() {
    let currentSearch = this.$route.params.sObj;
    //console.log("hobj " + JSON.stringify(currentSearch));

    this.startDate = currentSearch.item.f_date;
    this.endDate = currentSearch.item.s_date;
    let pivotDate = new Date("2022-07-18");
    let pivotDate1 = new Date("2022-10-24");
    let novDate = new Date("2022-11-18");
    let junDate = new Date("2023-06-08");
    let julDate24 = new Date("2024-08-13");

    let dateParts = this.endDate.split("-");
    let currDate = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
    console.log(currDate);

    currDate = new Date(currDate);
    let allProducts = null;
    if (currDate > julDate24) {
      //console.log("finding latest findJuly24Products");
      allProducts = await this.$store.dispatch("findJuly24Products");
    } else if (currDate > junDate && currDate < julDate24) {
      //console.log("finding june 23");
      allProducts = await this.$store.dispatch("findJune23Products");
    } else if (currDate > novDate && currDate < junDate) {
      allProducts = await this.$store.dispatch("findNov22Products");
    } else if (currDate > pivotDate && currDate < pivotDate1) {
      allProducts = await this.$store.dispatch("findNewProducts");
    } else if (currDate > pivotDate1 && currDate < novDate) {
      allProducts = await this.$store.dispatch("findNewerProducts");
    } else if (currDate <= pivotDate) {
      allProducts = await this.$store.dispatch("findOldProducts");
    }

    // var allProducts = await this.$store.dispatch("findProducts");
    if (currentSearch.pubmed == 0) {
      this.journals = 1;
      var allJournals = await this.$store.dispatch("findJournals");
    } else {
      this.journals = 0; //findPubmedJournals
      allJournals = await this.$store.dispatch("findPubmedJournals");
    }
    await this.initiateReport(allProducts, allJournals);
    //console.log(allProducts.length);
    //console.log(allJournals.length);
  },
  async beforeDestroy() {},
};
</script>
