<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" lg="8">
      <v-autocomplete
        v-model="checkedItems"
        :items="items"
        item-text="name"
        item-value="id"
        label="Product List"
        outlined
        dense
        chips
        small-chips
        multiple
        prepend-inner-icon="mdi-search"
      >
        <v-list-tile slot="prepend-item" class="grey--text">
          <div v-if="hist">{{ items.length }} Products Listed</div>
          <div v-else>
            Select a maximum of 4 products - {{ items.length }} Products Listed
          </div>
        </v-list-tile>
        <!--
        <template v-slot:prepend-item>
          <v-list-item ripple></v-list-item>
        </template>-->
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      value: [],
    },
    hist: {
      type: Boolean,
      value: false,
    },
  },
  data: () => ({
    checkedItems: [],
  }),
  methods: {
    toggle() {
      this.$nextTick(() => {
        this.checkedItems = this.items.slice();
      });
    },
  },
  computed: {
    icon() {
      if (this.selectedSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    checkedItems: function() {
      this.$emit("update-product", this.checkedItems);
    },
  },
};
</script>
