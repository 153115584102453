<template>
  <div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="histType(item.title)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  data: () => ({
    x: 0,
    y: 0,
    items: [],
  }),
  props: {
    showMenu: {
      type: Boolean,
    },
    viewProds: {
      type: Boolean,
    },
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    histType(item) {
      this.$emit("fetch-type", item);
    },
  },
  async created() {
    this.items.push({ title: "Download Report" });
    this.viewProds = Boolean(0); //hack to stop showing product view for now
    if (this.viewProds) {
      this.items.push({ title: "View Products/Journals" });
    }
    this.items.push({ title: "Pdf Products/Journals" });
  },
};
</script>
