<template>
  <v-app id="app">
    <v-container>
      <app-header></app-header>
      <router-view />
    </v-container>
  </v-app>
</template>
<script>
import Header from "@/components/Header.vue";
export default {
  name: "app",
  components: {
    "app-header": Header,
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.v-navigation-drawer {
  z-index: 999999 !important;
}
</style>
