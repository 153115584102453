import Vue from "vue";
import App from "./App.vue";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import routes from "./router";

Vue.use(VueRouter);
//Vue.prototype.$workbox = wb;

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

Vue.config.productionTip = false;

new Vue({
  router: router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
