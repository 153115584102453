<template>
  <v-app id="inspire">
    <v-layout justify-center>
      <v-card width="400" height="300" style="margin-top: 50px">
        <v-card-title class="pb-0">
          <h2>User Login</h2>
        </v-card-title>
        <v-card-text>
          <v-form class="mt-5">
            <v-text-field
              class="pt-4"
              v-model="creds.email"
              label="Username"
              prepend-icon="mdi-account-circle"
            />
            <v-text-field
              v-model="creds.pword"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
            />
          </v-form>
          <v-alert :value="validationerror" color="error"></v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <!--
          <router-link to="/changepass" style="color:blue; font-size: 10px"
            >forgot password?</router-link>-->
          <v-spacer />
          <v-btn color="light-blue" class="white--text" @click="login"
            >Login</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      creds: {
        email: "",
        pword: "",
      },
      insCoy: "",
      error: null,
      validationerror: false,
      showPassword: false,
    };
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch("loginUser", this.creds);

        this.validationerror = false;
        var srchUser = this.$store.getters.getUser;

        if (!srchUser.active) {
          throw " User not Active";
        } else {
          await this.$store.dispatch("setUpUser", srchUser);
          sessionStorage.setItem(
            "token",
            JSON.stringify(this.$store.getters.getToken)
          );
          sessionStorage.setItem(
            "user",
            JSON.stringify(this.$store.getters.getUser)
          );
        }
        if (!this.$store.getters.isLoggedIn) {
          await this.$store.dispatch("setLogin", false);
          throw "Login Error!";
        } else {
          this.$router.push("/search");
        }
      } catch (error) {
        this.error = error;
        this.validationerror = true;
      }
    },
  },
  async beforeDestroy() {
    if (!(await this.$store.getters.getUser)) {
      await this.$store.dispatch("setUpUser", { email: this.creds.email });
    }
    //console.log('email '+ await JSON.stringify(this.$store.getters.getUser))
  },
  async created() {
    await this.$store.dispatch("setUpUser", null);
  },
};
</script>
<style media="screen">
.loginStyler {
  padding-bottom: 0.2rem;
  font-weight: 600;
}
.theme--light.v-application {
  background: none !important;
  color: rgba(0, 0, 0, 0.87);
}

.flex > .v-card {
  opacity: 0.8;
}
.mx-auto:hover {
  background-color: white !important;
}
.Absolute-Center.is-Responsive {
  width: 50%;
  height: 50%;
  min-width: 200px;
  max-width: 400px;
  padding: 40px;
}
</style>
