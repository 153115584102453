<template>
  <v-layout>
    <v-flex>
      <br />
      <v-btn color="light-blue" class="white--text" small @click="returnHome"
        >return home
      </v-btn>
    </v-flex>
  </v-layout>
</template>
<script>
import jspdf from "jspdf";
import { getFormattedDate } from "@/services/dateFormatter.js";

export default {
  data() {
    return {
      resolution: {},
      moneyConfig: {
        decimal: ".",
        thousands: ",",
        prefix: "R ",
        precision: 2,
        masked: false,
      },
      doc: {},
      caseId: 0,
      startDate: null,
      endDate: null,
      createdAt: null,
      productsFound: [],
    };
  },
  methods: {
    async initiateReport(hits, numHits) {
      this.doc = new jspdf({ filters: ["ASCIIHexEncode"] });
      this.doc.setDrawColor(100, 100, 0);
      this.doc.page = 1;
      this.doc.setFont("Helvetica"); // set font
      this.doc.setFontSize(11);
      this.header();

      let ypos = 50;
      let rectWidth = this.doc.internal.pageSize.width - 11;

      if (numHits < 1) {
        this.doc.text(
          60,
          ypos + 50,
          "----------------------No product Hits returned--------------------"
        );
        this.footer();
        await this.doc.save("Literature Search Results.pdf");
        return;
      }

      ypos = this.displayProductsTop(ypos);
      ypos += 4;
      let count = 1;
      for (let i = 0; i < hits.length; i++) {
        let result = hits[i].entry.hit;
        let txt = await this.cleanUpText(result.head1);
        let rectHeight = this.calcRectHeight(
          4,
          txt,
          hits[i].entry.products.length
        );
        if (ypos + rectHeight > 260) {
          this.addPageFiller(ypos, count, rectWidth);
          await this.footer();
          await this.doc.addPage();
          this.doc.page++;
          ypos = await this.header();
          ypos += 4;
        }

        if (this.isEven(count)) this.doc.setFillColor(230, 230, 250);
        if (this.isOdd(count)) this.doc.setFillColor(220, 210, 240);

        this.doc.rect(5.5, ypos - 5, rectWidth, rectHeight, "F");
        count++;
        ypos = this.displayCitation(hits[i].key, ypos);
        this.doc.text(22, ypos, "Country:");
        this.doc.text(40, ypos, "South Africa");
        ypos += 6;
        this.doc.text(17, ypos, "Short Desc:");
        this.doc.text(40, ypos, txt);
        let txtHeight =
          this.noLinesInTxt(txt) < 3 ? 6 : this.noLinesInTxt(txt) * 3.3;
        ypos += txtHeight;
        txt =
          hits[i].entry.products.length > 1
            ? "Products in article:"
            : "Product in article:";
        this.doc.text(8, ypos, txt);
        for (var x = 0; x < hits[i].entry.products.length; x++) {
          this.doc.text(40, ypos, hits[i].entry.products[x]);
          ypos += 4;
        }
        ypos += 3;
        //this.doc.text(15, ypos, "Link to abstract:");
        this.doc.textWithLink("Click here to view abstract", 40, ypos, {
          url: result.href,
        });
        ypos += 1;
        this.doc.setDrawColor(0, 0, 255);
        this.doc.setLineWidth(0.3);
        this.doc.line(40, ypos, 81, ypos);

        this.doc.setDrawColor(0, 0, 255);
        ypos += 8;
      }
      this.addPageFiller(ypos, count, rectWidth, true);
      this.footer();
      await this.doc.save("Literature Search Results Adcock Ingram.pdf");
    },
    async setCounters(cObj, txtLines) {
      cObj.posx = 12;
      cObj.posy = cObj.posy > 0 ? cObj.posy + cObj.ch + 10 : 40;
      cObj.ch = txtLines.length * 5 + 20;

      return cObj;
    },
    getProductList(sList) {
      var products = new Array(sList.length);
      for (let i = 0; i < sList.length; i++) {
        products[i] = sList[i].response.product;
      }
      return products;
    },
    displayProductsTop(ypos) {
      this.productsFound = this.uniq(this.productsFound);
      for (let i = 0; i < this.productsFound.length; i++) {
        this.doc.text(40, ypos, this.productsFound[i]);
        ypos += 5;
      }
      return ypos;
    },
    displayCitation(citation, ypos) {
      this.doc.setFontSize(8);
      this.doc.setFont("times", "italic");
      this.doc.text(40, ypos, citation);

      this.doc.setFont("Helvetica", "normal");
      this.doc.setFontSize(10);
      ypos += 6;

      return ypos;
    },
    uniq(a) {
      return a.filter(function(item, pos, ary) {
        return !pos || item != ary[pos - 1];
      });
    },
    calcRectHeight(initHeight, txt, products) {
      let h = initHeight;
      let citationHeight = 5;
      h += citationHeight;
      let txtHeight =
        this.noLinesInTxt(txt) < 3 ? 6 : this.noLinesInTxt(txt) * 3.3;
      let countryHeight = 7;
      h += countryHeight;
      h += txtHeight;
      let productsHeight = 5 * products;
      h += productsHeight;
      let linkHeight = 7;
      h += linkHeight;
      return h;
    },
    encode_utf8(s) {
      return unescape(encodeURIComponent(s));
    },
    cleanUpText(txt) {
      txt = txt.replace(/[^a-zA-Z0-9' '/,/.:\n/*+-]/g, "");
      txt = this.encode_utf8(txt);
      if (this.isUpper(txt)) {
        txt = txt.replace(/(.{1,70})(?:\n|$| )/g, "$1\n");
      } else {
        txt = txt.replace(/(.{1,90})(?:\n|$| )/g, "$1\n");
      }

      txt += ".";
      return txt;
    },
    isEven(x) {
      return x % 2 == 0;
    },
    isOdd(x) {
      return !this.isEven(x);
    },
    isUpper(str) {
      return !/[a-z]/.test(str) && /[A-Z]/.test(str);
    },
    formatDateRange(date) {
      date = date.substr(0, 10);
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    noLinesInTxt(txt) {
      return txt.split(/\n/).length;
    },
    addPageFiller(ypos, count, w, done) {
      let h = this.doc.internal.pageSize.height - 14;
      h -= ypos;
      if (this.isEven(count)) this.doc.setFillColor(230, 230, 250);
      if (this.isOdd(count)) this.doc.setFillColor(220, 210, 240);
      this.doc.rect(5.5, ypos - 5, w, h, "F");
      this.doc.setFontSize(12);
      if (done) {
        this.doc.text(
          40,
          ypos + 8,
          "----------------------End Of Report--------------------"
        );
      }
    },
    async getLongestLine(txtLines) {
      var l = 0;
      for (var i = 0; i < txtLines.length; i++) {
        l = txtLines[i].length > l ? txtLines[i].length : 180;
      }
      if (l < 181) {
        l = 180;
      } else {
        l += 9;
      }
      return l;
    },
    async header() {
      this.doc.setFillColor(255, 255, 250);
      this.doc.roundedRect(
        5,
        5,
        this.doc.internal.pageSize.width - 10,
        this.doc.internal.pageSize.height - 10,
        5,
        5,
        "FD"
      );
      this.doc.setFontSize(12);
      this.doc.setTextColor(0, 0, 0);
      this.doc.line(5, 43, 205, 43, "FD");
      this.doc.setFontSize(18);
      this.doc.setTextColor(100, 100, 100);
      this.doc.text(50, 15, "Literature Search Reflecting Products");
      this.doc.setFontSize(18);
      this.doc.text(80, 24, "Adcock Ingram");
      this.doc.setFontSize(10);
      this.doc.setTextColor(100, 100, 100);

      this.doc.text(163, 24, "Date Range:");
      this.doc.text(185, 24, this.formatDateRange(this.startDate));
      this.doc.text(192, 28, "To");
      this.doc.text(185, 32, this.formatDateRange(this.endDate));

      this.doc.setFontSize(12);
      this.doc.text(90, 32, getFormattedDate(new Date(), false, true));
      this.doc.setDrawColor(0, 0, 0);
      this.doc.setFontSize(10);
      this.doc.setTextColor(0, 0, 0);
      this.doc.text(6, 50, "Products Searched:");
      let ypos = await this.displayProductsTop(50);
      return ypos;
    },
    async footer() {
      this.doc.setFontSize(9);
      var ph = this.doc.internal.pageSize.height;
      this.doc.setLineWidth(0.1);
      this.doc.setDrawColor(0, 0, 0);
      this.doc.line(5, ph - 20, 205, ph - 20, "FD");
      /*
      const logo = require("@/assets/hkb-slogo.png");
       var image = new Image();
      image.src = logo;
      this.doc.addImage(image, "PNG", 10, ph - 14, 30, 20);*/
      this.doc.text(10, ph - 14, "HKB-tech");
      this.doc.text(
        154,
        ph - 14,
        "Date created: " + getFormattedDate(new Date(), true)
      );

      this.doc.text(179, ph - 8, " page: " + this.doc.page);
    },
    async returnHome() {
      this.$router.push({
        path: "/",
      });
    },
  },
  async created() {
    let currentSearch = await this.$store.dispatch("findSearches");

    this.startDate = currentSearch.startDate;
    this.endDate = currentSearch.endDate;
    let hitList = [];
    let sObj = {
      searchId: currentSearch.id,
      siteId: 1,
    };
    hitList = await this.$store.dispatch("findSearchResp", sObj);
    let artProds = new Map();
    for (var i = 0; i < hitList.length; i++) {
      var resps = hitList[i].response.scrape;
      var product = hitList[i].response.product;
      //var SiteId = hitList[i].SiteId;

      //new String("a").valueOf() == new String("a").valueOf()
      for (var x = 0; x < resps.length; x++) {
        if (artProds.has(resps[x].journal + " " + resps[x].volume)) {
          var entry = artProds.get(resps[x].journal + " " + resps[x].volume);
          entry.products.push(product);
        } else {
          artProds.set(resps[x].journal + " " + resps[x].volume, {
            products: [product],
            hit: resps[x],
          });
        }
      }
    }
    this.productsFound = this.getProductList(hitList);
    hitList = [];
    artProds.forEach((value, key) => {
      hitList.push({ entry: value, key: key });
    });

    //let ps = this.getProductList(hitList);
    await this.initiateReport(hitList, resps.length);
  },
  async beforeDestroy() {},
};
</script>
