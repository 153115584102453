import Vue from "vue";
import Vuex from "vuex";
import Api from "@/services/Api";

Vue.use(Vuex);

export const store = new Vuex.Store({
  strict: true,
  state: {
    product: null,
    products: [],
    journal: null,
    journals: [],
    botJournal: null,
    botJournals: [],
    pubmedJournals: [],
    site: null,
    sites: [],
    users: [],
    user: null,
    token: null,
    loggedIn: false,
    searches: [],
    search: null,
    pubSearches: [],
    pubSearch: null,
    searchResps: [],
    searchObj: {
      startDate: null,
      endDate: null,
      searchProducts: {
        all: false,
        products: [],
      },
      searchSites: {
        all: false,
        sites: [],
      },
    },
    respObj: {},
  },
  getters: {
    getUsers: (state) => {
      return state.users;
    },
    getUser: (state) => {
      return state.user;
    },
    getToken: (state) => {
      return state.token;
    },
    getProduct: (state) => {
      return state.product;
    },
    getProducts: (state) => {
      return state.products;
    },
    getJournal: (state) => {
      return state.journal;
    },
    getJournals: (state) => {
      return state.journals;
    },
    getBotJournal: (state) => {
      return state.botJournal;
    },
    getBotJournals: (state) => {
      return state.botJournals;
    },
    getPubmedJournals: (state) => {
      return state.pubmedJournals;
    },
    getSite: (state) => {
      return state.site;
    },
    getSites: (state) => {
      return state.sites;
    },
    getSearches: (state) => {
      return state.searches;
    },
    getsearch: (state) => {
      return state.search;
    },
    getPubSearches: (state) => {
      return state.pubSearches;
    },
    getPubSearch: (state) => {
      return state.pubSearch;
    },
    getSearchResps: (state) => {
      return state.searchResps;
    },
    getSearchObj: (state) => {
      return state.searchObj;
    },
    getRespObj: (state) => {
      return state.respObj;
    },
    isLoggedIn: (state) => {
      return state.loggedIn;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setProduct(state, product) {
      state.product = product;
    },
    setProducts(state, products) {
      state.products = products;
    },
    setJournal(state, journal) {
      state.journal = journal;
    },
    setJournals(state, journals) {
      state.journals = journals;
    },
    setBotJournal(state, botJournal) {
      state.journal = botJournal;
    },
    setBotJournals(state, botJournals) {
      state.botJournals = botJournals;
    },
    setPubmedJournals(state, pubmedJournals) {
      state.pubmedJournals = pubmedJournals;
    },
    setSite(state, site) {
      state.site = site;
    },
    setSites(state, sites) {
      state.sites = sites;
    },
    setSearches(state, searches) {
      state.searches = searches;
    },
    setSearch(state, search) {
      state.search = search;
    },
    setPubSearches(state, pubSearches) {
      state.pubSearches = pubSearches;
    },
    setPubSearch(state, pubSearch) {
      state.pubSearch = pubSearch;
    },
    setSearchResps(state, searchResps) {
      state.searchResps = searchResps;
    },
    setSearchObj(state, searchObj) {
      state.searchObj = searchObj;
    },
    setRespObj(state, respObj) {
      state.respObj = respObj;
    },
  },
  actions: {
    setLogin({ commit }, login) {
      commit("setLoggedIn", login);
    },
    setUpUser({ commit }, user) {
      commit("setUser", user);
    },
    setUpToken({ commit }, token) {
      commit("setToken", token);
      Api.defaults.headers.common["authorization"] = this.state.token;
    },
    findProducts({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/products").then(
          (response) => {
            //console.log('findCase '+ response.data);
            commit("setProducts", response.data);
            resolve(this.state.products);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findOldProducts({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/productsold").then(
          (response) => {
            //console.log('findCase '+ response.data);
            commit("setProducts", response.data);
            resolve(this.state.products);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findNewProducts({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/productsnew").then(
          (response) => {
            //console.log('findCase '+ response.data);
            commit("setProducts", response.data);
            resolve(this.state.products);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findNov22Products({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/productsnov22").then(
          (response) => {
            //console.log('findCase '+ response.data);
            commit("setProducts", response.data);
            resolve(this.state.products);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findJune23Products({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/productsjune23").then(
          (response) => {
            //console.log("findCase " + response.data);
            commit("setProducts", response.data);
            resolve(this.state.products);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findJuly24Products({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/productsjuly24").then(
          (response) => {
            //console.log('findCase '+ response.data);
            commit("setProducts", response.data);
            resolve(this.state.products);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findNewerProducts({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/productsnewer").then(
          (response) => {
            //console.log('findCase '+ response.data);
            commit("setProducts", response.data);
            resolve(this.state.products);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findJournals({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/journals").then(
          (response) => {
            //console.log('findCase '+ response.data);
            commit("setJournals", response.data);
            resolve(this.state.journals);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findBotJournals({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/botJournals").then(
          (response) => {
            //console.log('findCase '+ response.data);
            commit("setBotJournals", response.data);
            resolve(this.state.botJournals);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findPubmedJournals({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/pubmedJournals").then(
          (response) => {
            //console.log('findCase '+ response.data);
            commit("setPubmedJournals", response.data);
            resolve(this.state.pubmedJournals);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findSites({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/sites").then(
          (response) => {
            //console.log('findCase '+ response.data);
            commit("setSites", response.data);
            resolve(this.state.sites);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findSearches({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/advsrches/max").then(
          (response) => {
            commit("setSearch", response.data);
            resolve(this.state.search);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findLitSearch({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/litsearch/max").then(
          (response) => {
            commit("setSearch", response.data);
            resolve(this.state.search);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findLitSearches({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/litsearch").then(
          (response) => {
            commit("setSearches", response.data);
            resolve(this.state.searches);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findPubSearch({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/pubsearch/max").then(
          (response) => {
            commit("setPubSearch", response.data);
            resolve(this.state.pubSearch);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findPubSearches({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/pubsearch").then(
          (response) => {
            commit("setPubSearches", response.data);
            resolve(this.state.pubSearches);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findSearchResp({ commit }, sObj) {
      return new Promise((resolve, reject) => {
        Api.get(`/srchresps/${sObj.searchId}/${sObj.siteId}`).then(
          (response) => {
            commit("setSearchResps", response.data);
            resolve(this.state.searchResps);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    findUsers({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get("/users").then(
          (response) => {
            //console.log('findCase '+ response.data);
            commit("setUsers", response.data);
            resolve(this.state.users);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    runSearch({ commit }, searchObj) {
      return new Promise((resolve, reject) => {
        //('createClaimdefend '+ JSON.stringify(claimDefend))

        Api.post("/runsrchs", searchObj).then(
          (response) => {
            commit("setRespObj", response.data);
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    saveSearchObj({ commit }, searchObj) {
      commit("setSearchObj", searchObj);
    },
    loginUser({ commit }, creds) {
      return new Promise((resolve, reject) => {
        Api.post("login", creds, {
          headers: {
            "Content-Type": "application/json",
          },
        }).then(
          (response) => {
            commit("setUser", response.data.srchUser);
            commit("setToken", response.data.token);
            commit("setLoggedIn", true);
            Api.defaults.headers.common["authorization"] = this.state.token;

            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
  },
  modules: {},
});
